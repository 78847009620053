<template>
  <div class="add-comment-modal-overlay">
    <div class="add-comment-modal">
      <img
        class="add-comment-modal-close"
        alt="x-icon"
        src="../../assets/x-icon.png"
        @click="closeModal"
      />
      <h3 class="add-comment-modal-title">{{ action }} Comment</h3>
      <textarea
        class="add-comment-modal-textarea"
        v-model="inputContent"
        :placeholder="commentStrings.placeholder"
        required
      ></textarea>
      <div class="add-comment-modal-buttons">
        <button class="button" @click="submitComment">Submit Comment</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Comments } from "../Shared/strings";

export default {
  data() {
    return {
      inputContent: this.selectedComment ? this.selectedComment.comment : "",
    };
  },
  props: ["action", "selectedComment"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
    submitComment() {
      if (this.inputContent.trim().length) {
        this.$emit("input-content", this.inputContent, this.action);
        this.closeModal();
      }
    },
  },
  computed: {
    commentStrings() {
      return Comments;
    },
  },
};
</script>

<style scoped lang="scss">
.add-comment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-comment-modal {
  position: relative;
  background-color: #fff;
  padding: 1.5em;
  border-radius: 0.5em;
  width: 25em;
  height: 25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    width: 0.7em;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  &-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0.5em;
    align-self: flex-start;
  }
  &-textarea {
    margin-bottom: 1em;
    border: 1px solid #e6eaf2;
    border-radius: 0.5em;
    width: 100%;
    height: 70%;
    resize: none;
    padding: 0.5em;
  }
  &-buttons {
    display: flex;
    justify-content: center;
    align-self: flex-end;
  }
  &-button {
    cursor: pointer;
    font-weight: 500;
    margin: 0.5em;
    padding: 0.5em 1em;
    background-color: #003366;
    color: white;
    border: none;
    border-radius: 0.5em;
    &:hover {
      background-color: #002b55;
    }
    &:active {
      transform: translateY(1px);
    }
  }
}
</style>
