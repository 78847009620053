<template>
  <div class="comments-section">
    <div class="comments-section-header">
      <h3 class="comments-section-header-title">Comments</h3>
      <button
        :disabled="
          userRole !== 'SuperAdmin' &&
          userRole !== 'Admin' &&
          userRole !== 'ViewEdit'
        "
        @click="openModal('add')"
        class="comments-section-header-addButton"
      >
        + Add Comment
      </button>
    </div>
    <div class="comments-details">
      <div class="comments-details-content">
        <CommentCard
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
          @onAction="handleOptions"
        />
      </div>
      <CommentsForm
        v-if="showEditModal"
        @close="closeModal"
        :action="'Edit'"
        :selected-comment="selectedComment"
        @input-content="commentAction"
      />
      <CommentsForm
        v-if="showAddModal"
        @close="closeModal"
        :action="'Add'"
        @input-content="commentAction"
      />
    </div>
  </div>
</template>

<script>
import CommentCard from "./CommentCard.vue";
import CommentsForm from "../Forms/CommentsForm.vue";
import { normalizeString } from "../Shared/helpers";

export default {
  data() {
    return {
      showEditModal: false,
      showAddModal: false,
      selectedOption: "default",
    };
  },
  components: {
    CommentCard,
    CommentsForm,
  },
  computed: {
    comments() {
      return this.$store.getters.getComments;
    },
    userRole() {
      return this.$store.getters.roleLoggedUser;
    },
  },
  methods: {
    closeModal() {
      this.showEditModal = false;
      this.showAddModal = false;
      this.inputContent = "";
    },
    openModal(type) {
      if (type === "edit") this.showEditModal = true;
      if (type === "add") this.showAddModal = true;
    },
    commentAction(input, action) {
      this.inputContent = input;
      switch (normalizeString(action)) {
        case "edit":
          this.$store
            .dispatch("editComment", {
              sensorId: this.$route.params,
              commentId: this.selectedComment.id,
              comment: this.inputContent,
            })
            .then(() => {
              this.getComments();
              this.closeModal();
            });
          return;
        case "add":
          this.$store.dispatch("addComment", {
            id: this.$route.params,
            comment: this.inputContent,
          });
          return;
      }
    },
    getComments() {
      this.$store.dispatch("getSensorComments", this.$route.params);
    },
    handleOptions(selectedOption, comment) {
      if (selectedOption.value === "delete") {
        this.$store
          .dispatch("deleteComment", {
            sensorId: this.$route.params.id,
            commentId: comment.id,
          })
          .then(() => {
            this.getComments();
          });
      } else if (selectedOption.value === "edit") {
        this.selectedComment = comment;
        this.openModal("edit");
      }
      this.selectedOption = "default";
    },
  },
  created() {
    this.$store.dispatch("getSensorComments", this.$route.params);
  },
};
</script>

<style scoped lang="scss">
.comments-section {
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5em;
    padding: 14px;
    @media screen and (max-width: 767px) {
      justify-content: center;
      gap: 11em;
    }
    &-title {
      font-size: 20px;
      font-weight: 600;
      margin-top: 0;
      @media screen and (max-width: 767px) {
        margin-left: 0px;
      }
    }
  }
  &-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1.1em;
    margin-top: 0px;
    &-title {
      font-size: 0.8em;
      font-weight: 700;
      color: #7c8088;
    }
  }
  .arrow-up-down {
    width: 0.9em;
    margin-left: 5px;
  }
}
.comments-details {
  &-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1.1em;
    margin-top: 0px;
    &-title {
      font-size: 0.8em;
      font-weight: 700;
      color: #7c8088;
    }
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .arrow-up-down {
    width: 0.9em;
    margin-left: 5px;
  }
}
option:disabled {
  display: none;
}
</style>
