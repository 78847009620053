<template>
  <div class="comments-details-content-card">
    <div class="comments-details-content-card-header">
      <div class="comments-details-content-card-header-detail">
        <p class="comments-details-content-card-header-detail-name">
          {{ comment.fullName }}
        </p>
        <p class="comments-details-content-card-header-detail-date">
          {{ getFormatDate(comment.lastModifiedAt) }}
        </p>
      </div>
      <Kebab
        @menuItemChanged="onKebabChange"
        :data="comment"
        :options="kebabOptions"
      />
    </div>
    <div class="comments-details-content-card-content">
      <p
        v-text="comment.comment"
        class="comments-details-content-card-content-note"
      ></p>
    </div>
  </div>
</template>

<script>
import Kebab from "../Shared/Kebab.vue";
export default {
  data() {
    const kebabOptions = {
      edit: {
        value: "edit",
        label: "Edit",
      },
      delete: {
        value: "delete",
        label: "Delete",
      },
    };
    return {
      kebabOptions,
    };
  },
  props: ["comment"],
  components: { Kebab },
  methods: {
    getFormatDate(date) {
      const localDateTime = new Date(date).toLocaleString("default", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });

      const dateTimeComponents = localDateTime.split(", ");
      const dayMonth = dateTimeComponents[0].split(" ").reverse().join(" ");
      const year = dateTimeComponents[1];
      const formattedDate = `${dayMonth.toUpperCase()} ${year}`;

      return formattedDate;
    },
    onKebabChange(selected, comment) {
      this.$emit("onAction", selected, comment);
    },
  },
};
</script>

<style scoped lang="scss">
.comments-details-content {
  &-card {
    border: 1px solid #e6eaf2;
    margin: 0.25em 1em 1em;
    width: 100%;
    border-radius: 0.5em;
    @media screen and (max-width: 767px) {
      width: auto;
    }
    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 1em 1.5em 0.25em 1.5em;
      font-size: 14px;
      &-detail {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        &-name {
          font-weight: 700;
          color: #616468;
        }
        &-date {
          color: #a5a7ae;
        }
      }
      &-bttn {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: transparent;
        width: 2em;
        border: none;
      }
    }
    &-content {
      margin: 0em 1.2em 1.5em 1.2em;
      font-size: 16px;
      &-note {
        font-size: var(--base-font-size);
      }
    }
  }
}
</style>
